import axios from 'axios';

// 定义接口来表示IP地址信息
interface IpInfoResponse {
  ip: string;
}

// 定义接口来表示国家信息
interface CountryInfoResponse {
  country: string;
}

// 获取用户的IP地址
export async function fetchIpAddress(): Promise<string> {
  try {
    const response = await axios.get<IpInfoResponse>(
      'https://api.ipify.org?format=json'
    );
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    throw error;
  }
}

// 根据IP地址获取国家信息
export async function fetchCountryByIp(ip: string): Promise<string> {
  try {
    const response = await axios.get<CountryInfoResponse>(
      `https://ipinfo.io/${ip}/json`
    );
    return response.data.country; // 或者 response.data.country_name 根据API返回的数据
  } catch (error) {
    console.error('Error fetching country information:', error);
    throw error;
  }
}
