import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Register from '../views/Register/Register.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/newhome/index.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact/index.vue'),
  },
  {
    path: '/text',
    name: 'OldHome',
    component: () => import('../views/home/index.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index/indexView.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/article',
    name: 'Article',
    component: () =>
      import(/* webpackChunkName: "article" */ '../views/Article.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
  },
  {
    path: '/emailLogin',
    name: 'EmailLogin',
    component: () =>
      import(
        /* webpackChunkName: "emailLogin" */ '../views/emailLogin/emailLogin.vue'
      ),
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () =>
      import(/* webpackChunkName: "invite" */ '../views/invite/invite.vue'),
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () =>
      import(/* webpackChunkName: "wallet" */ '../views/wallet/wallet.vue'),
  },
  {
    path: '/become_intelligent',
    name: 'become_intelligent',
    component: () =>
      import(
        /* webpackChunkName: "become_intelligent" */ '../views/become_intelligent/become_intelligent.vue'
      ),
  },
  {
    path: '/intelligent',
    name: 'intelligent',
    component: () =>
      import(
        /* webpackChunkName: "intelligent" */ '../views/intelligent/intelligent.vue'
      ),
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: () =>
      import(
        /* webpackChunkName: "copyright" */ '../views/copyright/copyright.vue'
      ),
  },
  {
    path: '/community',
    name: 'community',
    component: () =>
      import(
        /* webpackChunkName: "community" */ '../views/community/community.vue'
      ),
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () =>
      import(/* webpackChunkName: "aboutus" */ '../views/aboutus/aboutus.vue'),
  },
  {
    path: '/changeAccount',
    name: 'changeAccount',
    component: () =>
      import(
        /* webpackChunkName: "changeAccount" */ '../views/changeAccount/changeAccount.vue'
      ),
  },
  {
    path: '/redemptionCode',
    name: 'redemptionCode',
    component: () =>
      import(
        /* webpackChunkName: "redemptionCode" */ '../views/redemptionCode/redemptionCode.vue'
      ),
  },
  {
    path: '/im',
    name: 'im',
    component: () => import(/* webpackChunkName: "im" */ '../views/im/im.vue'),
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () =>
      import(
        /* webpackChunkName: "userAgreement" */ '../views/userAgreement/userAgreement.vue'
      ),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ '../views/privacyPolicy/privacyPolicy.vue'
      ),
  },
  {
    path: '/open_intelligent',
    name: 'open_intelligent',
    component: () =>
      import(
        /* webpackChunkName: "open_intelligent" */ '../views/open_intelligent/open_intelligent.vue'
      ),
  },
  {
    path: '/transactionServeList',
    name: 'transactionServeList',
    component: () =>
      import(
        /* webpackChunkName: "transactionServeList" */ '../views/transactionServe/transactionServeList.vue'
      ),
  },
  {
    path: '/dailytasks',
    name: 'dailytasks',
    component: () =>
      import(
        /* webpackChunkName: "dailytasks" */ '../views/dailytasks/dailytasks.vue'
      ),
  },
  {
    path: '/paystatus',
    name: 'paystatus',
    component: () =>
      import(
        /* webpackChunkName: "paystatus" */ '../views/paystatus/paystatus.vue'
      ),
  },
  {
    path: '/redemption',
    name: 'redemption',
    component: () =>
      import(
        /* webpackChunkName: "redemption" */ '../views/redemption/redemption.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
