import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/email_login_logo.png'
import _imports_1 from '@/assets/img/email_login.png'
import _imports_2 from '@/assets/img/code.png'
import _imports_3 from '@/assets/img/icon_look_yellow.png'
import _imports_4 from '@/assets/img/sucai.png'


const _hoisted_1 = { class: "register" }
const _hoisted_2 = { class: "f1 col-center content" }
const _hoisted_3 = { class: "mt16 notice" }
const _hoisted_4 = { class: "input-box row-center" }
const _hoisted_5 = { class: "input-box row-center" }
const _hoisted_6 = { class: "input-box row-center" }
const _hoisted_7 = { class: "input-box row-center" }
const _hoisted_8 = { class: "input-box row-center" }
const _hoisted_9 = ["loading"]
const _hoisted_10 = ["loading"]
const _hoisted_11 = { class: "mt16 policy" }

import { fetchCode, fetchLogin, fetchRegister } from '@/api/fetcher';
import Navi from '@/components/Navi.vue';
import { lang } from '@/lang';
import { ElForm, ElFormItem, ElInput } from 'element-plus';
import type { FormInstance, FormRules } from 'element-plus';
import { Loading } from 'vant';
import { reactive, ref, onMounted, onUnmounted } from 'vue';
import CountDown from '@/components/CountDown';
import dataCenter from '@/dataCenter';
import { useRouter, useRoute } from 'vue-router';
import Language from '@/components/Language.vue';
import { fetchIpAddress, fetchCountryByIp } from '@/api/configCountry';
import { LoginByEmailReq } from '@/api/protocol';

export default /*@__PURE__*/_defineComponent({
  __name: 'Register',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const isLoginOrRegister = ref(false);
const isPasswordOrCaptcha = ref(false);

const formRef = ref<FormInstance>();
const rules: FormRules = {
  email: [
    {
      required: true,
      pattern: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
      trigger: 'blur',
      message: lang.v.emailRuleError,
    },
  ],
  code: [{ required: true, trigger: 'blur' }],
  password: [
    {
      required: true,
      trigger: 'blur',
      message: lang.v.enterPassword,
    },
  ],
  secondPassword: [
    {
      required: true,
      trigger: 'blur',
      message: lang.v.enterSecondPassword,
    },
    //第二处输入密码与第一次相同
    {
      validator: (rule, value) => {
        if (!value) return true;
        return value === form.password;
      },
      trigger: 'blur',
      message: lang.v.confirmPassword,
    },
  ],
};

const form = reactive({
  email: '',
  code: '',
  refer: '',
  password: '',
  secondPassword: '',
});
const codeInfo = reactive({ text: lang.v.getCode, loading: false, count: 0 });
const submiting = ref(false);

const countDown = new CountDown();

const countryList = [
  { lang: 'en', code: 'US' },
  { lang: 'hi', code: 'IN' },
  { lang: 'in', code: 'ID' },
  { lang: 'pt', code: 'BR' },
  { lang: 'vi', code: 'VN' },
];
//设置登录注册函数
const onShowRegister = (showLogin: boolean) => {
  formRef.value?.resetFields();
  isLoginOrRegister.value = showLogin;
  if (!showLogin) {
    isPasswordOrCaptcha.value = showLogin;
  }
};
//设置密码登录验证码登录函数
const onShowCaptcha = (showCaptcha: boolean) => {
  formRef.value?.resetFields();
  isPasswordOrCaptcha.value = showCaptcha;
};
const getLang = (country: string) => {
  countryList.forEach(item => {
    if (item.code === country) {
      lang.change(item.lang);
    } else {
      lang.change('en');
    }
  });
};
const getCountryByIp = async () => {
  loading.value = true;
  const ip = await fetchIpAddress().catch(() => {
    loading.value = false;
  });
  if (!ip) return;
  const country = await fetchCountryByIp(ip).finally(() => {
    loading.value = false;
  });
  if (country) {
    getLang(country);
  }
};
onMounted(async () => {
  await getCountryByIp();
  if (route.query.lang) {
    lang.change(route.query.lang as string);
  }
  form.refer = dataCenter.webConfig.query.ref;
});
onUnmounted(() => {
  countDown.clear();
});

async function onGetCode() {
  if (codeInfo.count) {
    return;
  }
  await formRef.value?.validateField('email');
  if (codeInfo.loading) {
    return;
  }
  codeInfo.loading = true;
  fetchCode(form.email)
    .then(resp => {
      if (!resp) {
        return;
      }
      countDown.start(90, value => {
        codeInfo.count = value;
        if (value) {
          codeInfo.text = `${value}S`;
        } else {
          codeInfo.text = lang.v.getCode;
        }
      });
    })
    .finally(() => {
      codeInfo.loading = false;
    });
}
async function onSubmitRegister() {
  if (submiting.value) {
    return;
  }
  await formRef.value?.validate();
  fetchRegister({
    email: form.email,
    clientType: 'PC',
    captcha: form.code,
    invitationCode: form.refer,
    password: form.password,
    secondPassword: form.secondPassword,
  })
    .then(resp => {
      if (resp) {
        doLogin({
          email: form.email,
          clientType: 'PC',
          password: form.password,
        });
      }
    })
    .finally(() => {
      submiting.value = false;
    });
}
async function onSubmitLogin() {
  if (submiting.value) {
    return;
  }
  const submitLoginList: LoginByEmailReq = {
    email: form.email,
    clientType: 'PC',
    invitationCode: form.refer,
    captcha: undefined,
    password: undefined,
  };
  await formRef.value?.validateField('email');
  if (isPasswordOrCaptcha.value) {
    await formRef.value?.validateField('code');
    submitLoginList.captcha = form.code;
  } else {
    await formRef.value?.validateField('password');
    submitLoginList.password = form.password;
  }
  doLogin(submitLoginList);
}
const doLogin = (loginList: LoginByEmailReq) => {
  submiting.value = true;
  fetchLogin(loginList)
    .then(resp => {
      if (resp) {
        dataCenter.user.data = resp.userInfo;
        router.push('/');
      }
    })
    .finally(() => {
      submiting.value = false;
    });
};

return (_ctx: any,_cache: any) => {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Navi, {
      title: _unref(lang).v.emailRegister
    }, {
      menu: _withCtx(() => [
        _createVNode(Language)
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _cache[16] || (_cache[16] = _createElementVNode("img", {
        src: _imports_0,
        class: "email-logo"
      }, null, -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(lang).v.registerNotice), 1),
      _createVNode(_unref(ElForm), {
        ref_key: "formRef",
        ref: formRef,
        style: {"width":"100%"},
        model: form,
        rules: rules
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(ElFormItem), {
            prop: "email",
            class: "mb0 mt16"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _cache[10] || (_cache[10] = _createElementVNode("img", {
                  src: _imports_1,
                  class: "icon"
                }, null, -1)),
                _createVNode(_unref(ElInput), {
                  class: "field",
                  modelValue: form.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.email) = $event)),
                  placeholder: _unref(lang).v.enterEmail,
                  maxlength: "120"
                }, null, 8, ["modelValue", "placeholder"])
              ])
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_unref(ElFormItem), {
            prop: "code",
            class: "mb0 mt16"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _cache[11] || (_cache[11] = _createElementVNode("img", {
                  src: _imports_2,
                  class: "icon"
                }, null, -1)),
                _createVNode(_unref(ElInput), {
                  class: "field",
                  modelValue: form.code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.code) = $event)),
                  placeholder: _unref(lang).v.enterCode,
                  maxlength: "10"
                }, null, 8, ["modelValue", "placeholder"]),
                _createElementVNode("span", {
                  class: "coder row-center",
                  onClick: onGetCode
                }, [
                  _withDirectives(_createVNode(_unref(Loading), { size: "small" }, null, 512), [
                    [_vShow, codeInfo.loading]
                  ]),
                  _withDirectives(_createElementVNode("span", null, _toDisplayString(codeInfo.text), 513), [
                    [_vShow, !codeInfo.loading]
                  ])
                ])
              ])
            ]),
            _: 1
          }, 512), [
            [_vShow, !isLoginOrRegister.value || isPasswordOrCaptcha.value]
          ]),
          _withDirectives(_createVNode(_unref(ElFormItem), {
            prop: "password",
            class: "mb0 mt16"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _cache[12] || (_cache[12] = _createElementVNode("img", {
                  src: _imports_3,
                  class: "icon"
                }, null, -1)),
                _createVNode(_unref(ElInput), {
                  class: "field",
                  modelValue: form.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.password) = $event)),
                  placeholder: _unref(lang).v.enterPassword,
                  maxlength: "120"
                }, null, 8, ["modelValue", "placeholder"])
              ])
            ]),
            _: 1
          }, 512), [
            [_vShow, !isPasswordOrCaptcha.value]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "mt16 register",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (onShowCaptcha(!isPasswordOrCaptcha.value)))
          }, [
            _withDirectives(_createElementVNode("span", null, _toDisplayString(_unref(lang).v.LoginCode), 513), [
              [_vShow, !isPasswordOrCaptcha.value]
            ]),
            _withDirectives(_createElementVNode("span", null, _toDisplayString(_unref(lang).v.LoginPassword), 513), [
              [_vShow, isPasswordOrCaptcha.value]
            ])
          ], 512), [
            [_vShow, isLoginOrRegister.value]
          ]),
          _withDirectives(_createVNode(_unref(ElFormItem), {
            prop: "secondPassword",
            class: "mb0 mt16"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _cache[13] || (_cache[13] = _createElementVNode("img", {
                  src: _imports_3,
                  class: "icon"
                }, null, -1)),
                _createVNode(_unref(ElInput), {
                  class: "field",
                  modelValue: form.secondPassword,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.secondPassword) = $event)),
                  placeholder: _unref(lang).v.enterSecondPassword,
                  maxlength: "120"
                }, null, 8, ["modelValue", "placeholder"])
              ])
            ]),
            _: 1
          }, 512), [
            [_vShow, !isLoginOrRegister.value]
          ]),
          _withDirectives(_createVNode(_unref(ElFormItem), {
            prop: "refer",
            class: "mb0 mt16"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _cache[14] || (_cache[14] = _createElementVNode("img", {
                  src: _imports_4,
                  class: "icon"
                }, null, -1)),
                _createVNode(_unref(ElInput), {
                  class: "field",
                  modelValue: form.refer,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.refer) = $event)),
                  disabled: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }, 512), [
            [_vShow, !isLoginOrRegister.value]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "mt16 submit center",
            loading: submiting.value,
            onClick: onSubmitLogin
          }, _toDisplayString(_unref(lang).v.signIn), 9, _hoisted_9), [
            [_vShow, isLoginOrRegister.value]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "mt16 submit center",
            loading: submiting.value,
            onClick: onSubmitRegister
          }, _toDisplayString(_unref(lang).v.registerIn), 9, _hoisted_10), [
            [_vShow, !isLoginOrRegister.value]
          ])
        ]),
        _: 1
      }, 8, ["model"]),
      _withDirectives(_createElementVNode("div", {
        class: "mt16 register",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (onShowRegister(true)))
      }, [
        _createElementVNode("span", null, _toDisplayString(_unref(lang).v.goLogin), 1)
      ], 512), [
        [_vShow, !isLoginOrRegister.value]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "mt16 register",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (onShowRegister(false)))
      }, [
        _createElementVNode("span", null, _toDisplayString(_unref(lang).v.goRegister), 1)
      ], 512), [
        [_vShow, isLoginOrRegister.value]
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createTextVNode(_toDisplayString(_unref(lang).v.signPropt) + " ", 1),
        _createElementVNode("span", {
          class: "imp",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(router).push('/article?type=policy')))
        }, _toDisplayString(_unref(lang).v.policy), 1),
        _cache[15] || (_cache[15] = _createTextVNode(" & ")),
        _createElementVNode("span", {
          class: "imp",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(router).push('/article?type=agreement')))
        }, _toDisplayString(_unref(lang).v.agreement), 1)
      ])
    ])
  ])), [
    [_directive_loading, loading.value]
  ])
}
}

})